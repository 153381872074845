






















import { Vue, Component } from "vue-property-decorator";
import { ComponentTypeDictionaryService } from "@/app/dynamic-components/forms/form-components/form-component.lookup";
import BaseSectionDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component-section.base";
import {DynamicFormEntry} from "@/app/dynamic-components/forms/dynamic-form.model";

export type FormSectionLinearMultiplicityMetaData = {
  direction: "vertical" | "horizontal";
  multiplicity: string;
  multiplicityKey: string;
  title: boolean;
  title_translated: boolean;
};

@Component({
  computed: {
    ComponentTypeDictionaryService() {
      return ComponentTypeDictionaryService;
    },
  },
})
export default class FormSectionLinearMultiplicity extends BaseSectionDynamicComponent<FormSectionLinearMultiplicityMetaData> {
  public entries: any[] = []

  onEntryChanged(newValue: DynamicFormEntry, oldValue: DynamicFormEntry) {
    const multiple = newValue.root?.resolveDataPath(newValue.metadata.multiplicity);
    if(multiple && Array.isArray(multiple)){
      this.entries = multiple;
    }else{
      this.entries = [];
    }

  }
}
