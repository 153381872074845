




























































































































import { Component } from "vue-property-decorator";
import { ComponentTypeDictionaryService } from "@/app/dynamic-components/forms/form-components/form-component.lookup";
import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import BaseDynamicCompositeComponent from "@/app/dynamic-components/forms/form-components/form-component-composite.base";
import { RenderItem, RenderType } from "@/app/Types";
import FormWrapper from "@/app/dynamic-components/forms/FormWrapper.vue";
import FormComponentExternalEntityBase from "@/app/dynamic-components/forms/form-components/form-component-ext-entity.base";
import { PlanningModel } from "@/app/models/planning/planning.model";
import { planningService } from "@/app/services/planning.service";
import Loader from "@/components/common/Loader.vue";
import { checkinService, CheckinService } from "@/app/services/checkin.service";
import { CheckinModel } from "@/app/models/checkin/checkin.model";
import FormDialogComponent from "@/app/components/dialog/form-dialog-component.vue";
import {
  FormDialogControl,
  FormDialogModel,
} from "@/app/components/dialog/form-dialog.model";
import { ExternalContext } from "@/app/contexts/externalContext";
import { DynamicForm } from "@/app/dynamic-components/forms/dynamic-form.model";

export type CheckinMetaData = {
  label: string;
  disabled?: boolean;
};

@Component({
  components: { FormDialogComponent, Loader, FormWrapper },
  computed: {
    ComponentTypeDictionaryService() {
      return ComponentTypeDictionaryService;
    },
  },
})
export default class FormComponentCheckin extends FormComponentExternalEntityBase<
  CheckinMetaData,
  CheckinModel
> {
  protected checkinModel: FormDialogModel | null = null;
  protected checkinControl: FormDialogControl | null = null;
  protected checkinId: string | null = null;

  protected checkinCompleteModel: FormDialogModel | null = null;
  protected checkinCompleteControl: FormDialogControl | null = null;

  protected checkinUpdateModel: FormDialogModel | null = null;
  protected checkinUpdateControl: FormDialogControl | null = null;
  protected cancelCheckinOnUpdate = true;

  protected displayFormModel: RenderItem | null = null;
  protected displayForm: DynamicForm | null = null;
  protected openCreateCheckin() {
    this.checkinControl?.show();
  }

  openCompleteCheckin() {
    if (!this.externalEntityId) return;
    this.checkinCompleteControl?.show(
      this.externalEntityId,
      this.externalEntity
    );
  }

  openCancelCheckin() {
    if (!this.externalEntityId) return;
    this.cancelCheckinOnUpdate = true;
    this.checkinUpdateControl?.show(this.externalEntityId, this.externalEntity);
  }

  openRestartCheckin() {
    if (!this.externalEntityId) return;
    this.cancelCheckinOnUpdate = false;
    this.checkinUpdateControl?.show(this.externalEntityId, this.externalEntity);
  }

  protected useAlternativeFetch(): boolean {
    return true;
  }

  mounted() {
    const regardingId = this.entry.root?.resolvePlaceholders(
        this.entry.metadata.regardingId
    );
    const regardingType = this.entry.root?.resolvePlaceholders(
        this.entry.metadata.regardingType
    );

    checkinService.getCheckinByReferenceId(regardingId).then((value1) => {
      if (value1) {
        this.checkinId = value1.id;
        this.externalEntity = value1;
        this.externalEntityId = value1.id;
      } else {
        this.checkinId = null;
        this.externalEntity = null;
        this.externalEntityId = null;
      }
      setTimeout(() => {
        this.displayForm?.patchForm(value1);
      }, 200);
      return value1;
    });
  }

  recieveFormView(form: DynamicForm) {
    this.displayForm = form;
  }

  protected recieveCheckinControl(control: FormDialogControl) {
    this.checkinControl = control;
    control.submitChanges = this.createCheckin;
    control.afterSubmitChanges = this.setExternalEntiity;
  }

  protected recieveCheckinCompleteControl(control: FormDialogControl) {
    this.checkinCompleteControl = control;
    control.submitChanges = this.completeCheckin;
    control.afterSubmitChanges = this.setExternalEntiity;
  }

  protected recieveCheckinUpdateControl(control: FormDialogControl) {
    this.checkinUpdateControl = control;
    control.submitChanges = this.updateCheckin;
    control.afterSubmitChanges = this.setExternalEntiity;
  }

  public async createCheckin(
    form: DynamicForm,
    formData: any,
    referenceId?: string
  ): Promise<boolean> {
    form.patchForm({
      expectedStartDate: new Date(),
      regardingId: this.entry.root?.resolvePlaceholders(
        this.entry.metadata.regardingId
      ),
      regardingType: this.entry.root?.resolvePlaceholders(
        this.entry.metadata.regardingType
      ),
      referenceId: this.entry.root?.resolvePlaceholders(
        this.entry.metadata.regardingId
      ),
      executorOrganisationId: this.$store.state.user.organizationId,
      markExecutorsAsBusy: true,
      linkName: this.entry.root?.resolvePlaceholders(
        this.entry.metadata.linkName
      ),
      linkPath: this.entry.root?.resolvePlaceholders(
        this.entry.metadata.linkPath
      ),
    });

    const result = await checkinService.createCheckin(form, form.formData).then((value) => {
      this.checkinId = value;
      return !!value;
    });
    if(!result) return false;
    console.warn('checkinId',this.checkinId);

    if(this.entry?.metadata?.additionalParties && Array.isArray(this.entry.metadata.additionalParties) && Array.from(this.entry.metadata.additionalParties).length > 0){
      const additionalParties = Array.from(this.entry.metadata.additionalParties);
      for (let i = 0; i < additionalParties.length; i++) {
        try{
          const additionalParty = additionalParties[i];
          await checkinService.addPartyToCheckin(this.checkinId || 'nan', this.entry.root?.resolvePlaceholders(additionalParty.contactIdReference) || 'nan', additionalParty.contactType)
        }catch (ex){
          console.error(ex);
        }
      }
    }

    return true;
  }

  public completeCheckin(
    form: DynamicForm,
    formData: any,
    referenceId?: string
  ): Promise<boolean> {
    if (!this.externalEntityId) return Promise.reject(false);
    form.patchForm({
      actualEndDate: new Date(),
    });
    return checkinService
      .completeCheckin(form, this.externalEntityId, form.formData)
      .then((value) => {
        this.checkinId = value;
        return !!value;
      });
  }

  public updateCheckin(
    form: DynamicForm,
    formData: any,
    referenceId?: string
  ): Promise<boolean> {
    if (!this.externalEntityId) return Promise.reject(false);

    if (this.cancelCheckinOnUpdate) {
      form.patchForm({
        actualEndDate: new Date(),
      });
    } else {
      form.patchForm({
        actualEndDate: null,
        expectedStartDate: new Date(),
      });
    }

    return checkinService
      .updateCheckin(
        form,
        this.externalEntityId,
        form.formData,
        this.cancelCheckinOnUpdate
      )
      .then((value) => {
        this.checkinId = value;
        return !!value;
      });
  }
  public setExternalEntiity(
    form: DynamicForm,
    formData: any,
    referenceId?: string
  ): Promise<any> {
    if (!this.checkinId) return Promise.reject();
    return checkinService.getCheckin(this.checkinId).then((value1) => {
      this.externalEntityId = value1.id;
      this.externalEntity = value1;
      setTimeout(() => {
        this.displayForm?.patchForm(value1);
      }, 200);
      return value1;
    });
  }

  protected async fetchExternalEntity(
    value: any
  ): Promise<CheckinModel | null> {
    return null;
  }

  protected async createExternalEntity(formData: any): Promise<string> {
    if (!this.form) return "";
    return Promise.resolve(this.checkinId || "");
  }

  protected async createExternalEntityAfterSave(
    sumbitData: any
  ): Promise<void> {
    return;
  }

  protected async updateExternalEntity(
    externalEntityId: string,
    externalEntity: CheckinModel | null,
    formData: any
  ) {
    return;
  }

  protected createDefinition(): RenderItem | null {
    if (!this.entry) return null;

    const form: RenderItem = {
      id: "root",
      name: "",
      type: RenderType.LinearSection,
      displayMode: "readonly",
      displayModeRule: "",
      computedValue: "",
      validators: [],
      metadata: {
        direction: "vertical",
      },
      children: [],
    };

    this.createCheckinCreate();
    this.createDisplayForm();
    this.createCheckinComplete();
    this.createCheckinCancel();
    return form;
  }

  private createCheckinCreate() {
    const mode = this.entry.resolvedDisplayMode;

    this.checkinModel = {
      externalContext:
        this.entry.root?.externalContext || new ExternalContext(),
      referenceType: "",
      referenceId: "",
      title: this.$tc("checkin.create.title"),
      submitLabel: this.$tc("checkin.create.submit"),
      destroyLabel: null,
      form: {
        id: "root",
        name: "",
        type: RenderType.LinearSection,
        displayMode: mode,
        displayModeRule: "",
        computedValue: "",
        validators: [],
        metadata: {
          direction: "vertical",
        },
        children: [
          {
            id: "expectedStartDate",
            name: "",
            type: RenderType.DateTimePicker,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
          {
            id: "expectedEndDate",
            name: this.$tc("checkin.create.expectedEndDate"),
            type: RenderType.DateTimePicker,
            displayMode: mode,
            displayModeRule: "",
            computedValue: "",
            validators: ["required()"],
            metadata: {},
          },
          {
            id: "regardingId",
            name: "",
            type: RenderType.TEXTFIELD,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
          {
            id: "regardingType",
            name: "",
            type: RenderType.TEXTFIELD,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
          {
            id: "referenceId",
            name: "",
            type: RenderType.TEXTFIELD,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
          {
            id: "executorsContactIds",
            name: "checkin.create.persons",
            type: RenderType.SelectPerson,
            displayMode: mode,
            displayModeRule: "",
            computedValue: "",
            validators: ["required()"],
            metadata: {
              roleIds: ["d8bb43dc-6a21-ee11-9967-6045bd895bfb"],
              multiple: true,
            },
          },
          {
            id: "executorOrganisationId",
            name: "",
            type: RenderType.TEXTFIELD,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
          {
            id: "markExecutorsAsBusy",
            name: "",
            type: RenderType.TEXTFIELD,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
          {
            id: "linkName",
            name: "",
            type: RenderType.TEXTFIELD,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {
              isDynamicApiField: true,
            },
          },
          {
            id: "linkPath",
            name: "",
            type: RenderType.TEXTFIELD,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {
              isDynamicApiField: true,
            },
          },
          {
            id: "description",
            name: this.$tc("checkin.create.description"),
            type: RenderType.TextArea,
            displayMode: mode,
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
        ],
      },
    };
  }

  private createCheckinComplete() {
    const mode = this.entry.resolvedDisplayMode;

    this.checkinCompleteModel = {
      externalContext:
        this.entry.root?.externalContext || new ExternalContext(),
      referenceType: "",
      referenceId: "",
      title: this.$tc("checkin.complete.title"),
      submitLabel: this.$tc("checkin.complete.submit"),
      destroyLabel: null,
      form: {
        id: "root",
        name: "",
        type: RenderType.LinearSection,
        displayMode: mode,
        displayModeRule: "",
        computedValue: "",
        validators: [],
        metadata: {
          direction: "vertical",
        },
        children: [
          {
            id: "expectedStartDate",
            name: "",
            type: RenderType.DateTimePicker,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
          {
            id: "expectedEndDate",
            name: this.$tc("checkin.create.expectedEndDate"),
            type: RenderType.DateTimePicker,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
          {
            id: "actualEndDate",
            name: "",
            type: RenderType.DateTimePicker,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
          {
            id: "executorsContactIds",
            name: "checkin.create.persons",
            type: RenderType.SelectPerson,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {
              roleIds: ["d8bb43dc-6a21-ee11-9967-6045bd895bfb"],
              multiple: true,
            },
          },
          {
            id: "executorOrganisationId",
            name: "",
            type: RenderType.TEXTFIELD,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
          {
            id: "markExecutorsAsBusy",
            name: "",
            type: RenderType.TEXTFIELD,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
          {
            id: "linkName",
            name: "",
            type: RenderType.TEXTFIELD,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {
              isDynamicApiField: true,
            },
          },
          {
            id: "linkPath",
            name: "",
            type: RenderType.TEXTFIELD,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {
              isDynamicApiField: true,
            },
          },
          {
            id: "description",
            name: this.$tc("checkin.create.description"),
            type: RenderType.TextArea,
            displayMode: mode,
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
        ],
      },
    };
  }

  private createCheckinCancel() {
    const mode = this.entry.resolvedDisplayMode;

    this.checkinUpdateModel = {
      externalContext:
        this.entry.root?.externalContext || new ExternalContext(),
      referenceType: "",
      referenceId: "",
      title: this.$tc("checkin.cancel.title"),
      submitLabel: this.$tc("checkin.cancel.submit"),
      destroyLabel: null,
      form: {
        id: "root",
        name: "",
        type: RenderType.LinearSection,
        displayMode: mode,
        displayModeRule: "",
        computedValue: "",
        validators: [],
        metadata: {
          direction: "vertical",
        },
        children: [
          {
            id: "expectedStartDate",
            name: "",
            type: RenderType.DateTimePicker,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
          {
            id: "expectedEndDate",
            name: this.$tc("checkin.create.expectedEndDate"),
            type: RenderType.DateTimePicker,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
          {
            id: "actualEndDate",
            name: "",
            type: RenderType.DateTimePicker,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
          {
            id: "executorsContactIds",
            name: "checkin.create.persons",
            type: RenderType.SelectPerson,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {
              roleIds: ["d8bb43dc-6a21-ee11-9967-6045bd895bfb"],
              multiple: true,
            },
          },
          {
            id: "executorOrganisationId",
            name: "",
            type: RenderType.TEXTFIELD,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
          {
            id: "markExecutorsAsBusy",
            name: "",
            type: RenderType.TEXTFIELD,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
          {
            id: "linkName",
            name: "",
            type: RenderType.TEXTFIELD,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {
              isDynamicApiField: true,
            },
          },
          {
            id: "linkPath",
            name: "",
            type: RenderType.TEXTFIELD,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {
              isDynamicApiField: true,
            },
          },
          {
            id: "description",
            name: this.$tc("checkin.create.description"),
            type: RenderType.TextArea,
            displayMode: mode,
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
        ],
      },
    };
  }

  private createDisplayForm() {
    this.displayFormModel = {
      id: "root",
      name: "",
      type: RenderType.LinearSection,
      displayMode: "readonly",
      displayModeRule: "",
      computedValue: "",
      validators: [],
      metadata: {
        direction: "vertical",
      },
      children: [
        {
          id: "dates",
          name: "",
          type: RenderType.LinearSection,
          displayMode: "readonly",
          displayModeRule: "",
          computedValue: "",
          validators: this.entry.validatorsNames,
          metadata: {
            SkipElementAsFormData: true,
            direction: "horizontal",
          },
          children: [
            {
              id: "expectedStartDate",
              name: this.$tc("checkin.create.startDate"),
              type: RenderType.DateTimePicker,
              displayMode: "readonly",
              displayModeRule: "",
              computedValue: "",
              validators: this.entry.validatorsNames,
              metadata: {},
            },
            {
              id: "expectedEndDate",
              name: this.$tc("checkin.create.expectedEndDate"),
              type: RenderType.DateTimePicker,
              displayMode: "readonly",
              displayModeRule: "",
              computedValue: "",
              validators: this.entry.validatorsNames,
              metadata: {},
            },
            {
              id: "actualEndDate",
              name: this.$tc("checkin.create.actualEndDate"),
              type: RenderType.DateTimePicker,
              displayMode: "readonly",
              displayModeRule: "",
              computedValue: "",
              validators: this.entry.validatorsNames,
              metadata: {},
            },
          ],
        },
        {
          id: "executorsContactIds",
          name: "checkin.create.persons",
          type: RenderType.SelectPerson,
          displayMode: "inherit",
          displayModeRule: "",
          computedValue: "",
          validators: [],
          metadata: {
            roleIds: ["d8bb43dc-6a21-ee11-9967-6045bd895bfb"],
            multiple: true,
          },
        },
        {
          id: "description",
          name: this.$tc("checkin.create.description"),
          type: RenderType.TextArea,
          displayMode: "inherit",
          displayModeRule: "",
          computedValue: "",
          validators: [],
          metadata: {},
        },
      ],
    };
  }
}
