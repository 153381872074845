

































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import { detailComponentLookup } from "@/app/dynamic-components/details/details-components/detail-component.lookup";
import { EventDictionary } from "@/app/events/event.dictionary";
import { DetailContext } from "@/app/contexts/detail.context";
import {
  DetailAction,
  DynamicDetailDefinition,
  DynamicDetailEntry
} from "@/app/dynamic-components/details/dynamic-detail.model";
import Loader from "@/components/common/Loader.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import router from "@/router";
import ActivityDependendViewBase from "@/app/views/activity-dependend-view.base";
import ExchangeComponent from "@/app/components/exchange/exchange-component.vue";
import { BehaviorSubject, Subscription } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { DetailComponentBase } from "@/app/dynamic-components/details/detail-component.base";
import store from "@/store";
import {formService} from "@/app/services/form.service";

@Component({
  components: { ExchangeComponent, PageHeader, Loader },
})
export default class DetailComponentDashboard extends DetailComponentBase {

  get attrs() {
    return {
      boilerplate: false,
    };
  }

  get dashboardTitle(){
    return this.$tc('pf.dashboard.name');
  }

  calculateWidth(slots: number){
    if(slots <= 0) slots = 1;

    if(this.windowWidth < 600) return '100%';
    if(this.windowWidth > 1600) return (slots * 16.6666) + '%';
    //return (slots * 33.3333) + '%';
    return (slots * 16.6666) + '%';
  }


  protected getDetailDefinition(definitionId: string): Promise<DynamicDetailDefinition> {
    console.warn('dashboard', definitionId);
    return formService.getFormDefinition(definitionId)
  }

  protected setDetailContext(data: any, detailDefinition: DynamicDetailDefinition | null) {
    this.detailContext.setData("activity", this.$store.state.activity.currentActivityDefinition);
    this.detailContext.setData("bccaId", store.state.appConfig.bccaId);
  }
}
