import ActivityDefinitionModel from "@/app/models/activity/activity-definition.model";
import store from "@/store";
import axios from "@/plugins/axios";
import {DynamicDetailDefinition} from "@/app/dynamic-components/details/dynamic-detail.model";
import {FormDialogModel} from "@/app/components/dialog/form-dialog.model";
import {ExternalContext} from "@/app/contexts/externalContext";

class FormService {
    async getFormDefinition(formDefinition: string): Promise<DynamicDetailDefinition> {
        const url = `${store.state.appConfig.apiBaseUrl}api/v2/forms/definition/${formDefinition}`;
        return axios
            .get<DynamicDetailDefinition>(url, {})
            .then((response) => {
                return response?.data;
            });
    }

    async getDialogDefinition(formDefinition: string): Promise<FormDialogModel | null> {
        const url = `${store.state.appConfig.apiBaseUrl}api/v2/forms/definition/${formDefinition}`;
        return axios
            .get<FormDialogModel>(url, {})
            .then((response) => {
                if(!response?.data) return null;
                return {
                    destroyLabel: response.data.destroyLabel || null,
                    externalContext: new ExternalContext(),
                    form: response.data.form,
                    referenceId: response.data.referenceId,
                    referenceType: response.data.referenceType,
                    submitLabel: response.data.submitLabel  || null,
                    title: response.data.title
                };
            });
    }
}
export const formService = new FormService();

