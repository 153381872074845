import Vue from "vue";
import TextFieldBase from "@/app/dynamic-components/forms/form-components/components/form-component-textfield.vue";
import AddressFormBase from "@/app/dynamic-components/forms/form-components/sections/AddressForm.DynamicView.vue";
import TestSection from "@/app/dynamic-components/forms/form-components/sections/TestSection.DynamicView.vue";
import ActivityDomainForm from "@/app/dynamic-components/forms/form-components/components/depricated/form-component-activitydomain.vue";
import DossierPlanningPanel from "@/components/dossiers/details/panels/DossierPlanningPanel.vue";
import FormComponentHeader from "@/app/dynamic-components/forms/form-components/components/form-component-header.vue";
import FormComponentSelectOrgInRole from "@/app/dynamic-components/forms/form-components/components/form-component-select-org-in-role.vue";
import FormComponentSelectPersonInRole from "@/app/dynamic-components/forms/form-components/components/form-component-select-person-in-role.vue";
import FormComponentLots from "@/app/dynamic-components/forms/form-components/components/form-component-lots.vue";
import TextAreaBase from "@/app/dynamic-components/forms/form-components/components/form-component-textarea.vue";
import AccordionView from "@/app/dynamic-components/forms/form-components/sections/AccordionView.DynamicView.vue";
import LinearSection from "@/app/dynamic-components/forms/form-components/sections/form-section-linear.vue";
import SelectBase from "@/app/dynamic-components/forms/form-components/components/depricated/form-component-select.vue";
import FileInputBase from "@/app/dynamic-components/forms/form-components/components/form-component-fileinput.vue";
import ContactDetailsFormBase from "@/app/dynamic-components/forms/form-components/components/depricated/form-component-contactdetails.vue";
import DynamicSelect from "@/app/dynamic-components/forms/form-components/components/form-component-selectv2.vue";
import CheckBoxBase from "@/app/dynamic-components/forms/form-components/components/form-component-checkbox.vue";
import RadioButtonBase from "@/app/dynamic-components/forms/form-components/components/form-component-radiobutton.vue";
import SwitchBase from "@/app/dynamic-components/forms/form-components/components/form-component-switch.vue";
import SliderBase from "@/app/dynamic-components/forms/form-components/components/form-component-slider.vue";
import DatePickerBase from "@/app/dynamic-components/forms/form-components/components/form-component-datepicker.vue";
import TimePickerBase from "@/app/dynamic-components/forms/form-components/components/form-component-timepicker.vue";
import PlannerBase from "@/app/dynamic-components/forms/form-components/components/form-component-planner.vue";
import DateTimePickerBase from "@/app/dynamic-components/forms/form-components/components/form-component-datetimepicker.vue";
import DynamicSelectFromOverview from "@/app/dynamic-components/forms/form-components/components/form-component-select-from-overview.vue";
import DynamicSelectFromEmbedded from "@/app/dynamic-components/forms/form-components/components/form-component-select-from-embedded.vue";
import FormComponentText from "@/app/dynamic-components/forms/form-components/components/form-component-text.vue";
import FormComponentMulticheckinSelector from "@/app/dynamic-components/forms/form-components/components/checkin/form-component-multicheckin-selector.vue";
import FormComponentOverviewSelection from "@/app/dynamic-components/forms/form-components/components/form-component-overview-selection.vue";
import FormComponentCheckin from "@/app/dynamic-components/forms/form-components/components/form-component-checkin.vue";
import FormComponentSelectOrgAndUserInRoles
  from "@/app/dynamic-components/forms/form-components/components/form-component-select-org-user-in-roles.vue";
import FormSectionLinearMultiplicity from "@/app/dynamic-components/forms/form-components/sections/form-section-linear-multiplicity.vue";

export default class FormComponentsDependencies {
  public registerDependencies() {
    Vue.component("TextFieldBase", TextFieldBase);
    Vue.component("AddressFormBase", AddressFormBase);
    Vue.component("TestSection", TestSection);
    Vue.component("ActivityDomainForm", ActivityDomainForm);
    Vue.component("PlanningForm", DossierPlanningPanel);
    Vue.component("FormComponentHeader", FormComponentHeader);
    Vue.component("FormComponentSelectOrgInRole", FormComponentSelectOrgInRole);
    Vue.component("FormComponentSelectPersonInRole", FormComponentSelectPersonInRole);
    Vue.component("FormComponentLots", FormComponentLots);
    Vue.component("TextAreaBase", TextAreaBase);
    Vue.component("Acordeon", AccordionView);
    Vue.component("LinearSection", LinearSection);
    Vue.component("FormSectionLinearMultiplicity", FormSectionLinearMultiplicity);
    Vue.component("SelectBase", SelectBase);
    Vue.component("FileInputBase", FileInputBase);
    Vue.component("ContactDetailsFormBase", ContactDetailsFormBase);
    Vue.component("DynamicSelect", DynamicSelect);
    Vue.component("CheckBoxBase", CheckBoxBase);
    Vue.component("RadioButtonBase", RadioButtonBase);
    Vue.component("SwitchBase", SwitchBase);
    Vue.component("SliderBase", SliderBase);
    Vue.component("DatePickerBase", DatePickerBase);
    Vue.component("TimePickerBase", TimePickerBase);
    Vue.component("DateTimePickerBase", DateTimePickerBase);
    Vue.component("PlannerBase", PlannerBase);
    Vue.component("DynamicSelectFromOverview", DynamicSelectFromOverview);
    Vue.component("DynamicSelectFromEmbedded", DynamicSelectFromEmbedded);
    Vue.component("FormComponentText", FormComponentText);
    Vue.component("FormComponentMulticheckinSelector", FormComponentMulticheckinSelector);
    Vue.component("FormComponentOverviewSelection", FormComponentOverviewSelection);
    Vue.component("FormComponentCheckin", FormComponentCheckin);
    Vue.component("FormComponentSelectOrgAndUserInRoles", FormComponentSelectOrgAndUserInRoles);

  }
}
export const formComponentsDependencies = new FormComponentsDependencies();
