import Vue from "vue";
import DetailComponentGeneralInformation from "@/app/dynamic-components/details/details-components/components/dossier/dossier-detail-component-general-information.vue";
import DetailComponentText from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-text.vue";
import DetailComponentSummary from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-summary.vue";
import DossierDetailComponentActions from "@/app/dynamic-components/details/details-components/components/dossier/dossier-detail-component-actions.vue";
import DossierDetailComponentButton from "@/app/dynamic-components/details/details-components/components/dossier/dossier-detail-component-button.vue";
import DetailComponentTabs from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-tabs.vue";
import DetailComponentExchanges from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-exchanges.vue";
import DossierDetailComponentLotTab from "@/app/dynamic-components/details/details-components/components/dossier/dossier-detail-component-lot-tab.vue";
import DossierDetailComponentTabsWithGeneralAndLots from "@/app/dynamic-components/details/details-components/components/dossier/dossier-detail-component-tabs-with-general-and-lots.vue";
import DossierDetailComponentGeneralTab from "@/app/dynamic-components/details/details-components/components/dossier/dossier-detail-component-general-tab.vue";
import DetailComponentOrganisation from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-organisation.vue";
import DetailComponentPerson from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-person.vue";
import DetailComponentList from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-list.vue";
import DetailComponentDocumentChecklist from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-document-checklist.vue";
import DetailComponentLinearSection from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-linear-section.vue";
import DetailComponentDossierLotSummary from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-dossier-lot-summary.vue";
import DetailComponentProducts from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-products.vue";
import DetailComponentLinearSectionMultiplicity from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-linear-section-multiplicity.vue";
import DetailComponentExchangeSteps from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-exchange-steps.vue";
import DetailComponentHeader from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-header.vue";
import ModifyEntitiesDialog from "@/app/dynamic-components/details/details-components/dialogs/ModifyLotsDialog.vue";
import DetailComponentAddress from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-address.vue";
import DetailComponentDate from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-date.vue";
import DetailComponentState from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-state.vue";
import DetailComponentPlanning from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-planning.vue";
import WidgetComponentLink from "@/app/dynamic-components/details/details-components/widgets/widget-component-link.vue";
import WidgetComponentText from "@/app/dynamic-components/details/details-components/widgets/widget-component-text.vue";
import WidgetComponentVertical from "@/app/dynamic-components/details/details-components/widgets/widget-component-vertical.vue";
import WidgetComponentDossierAtgs from "@/app/dynamic-components/details/details-components/widgets/widget-component-dossier-atgs.vue";
import DetailComponentContact
  from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-contact.vue";
import DetailComponentStatusUpdater
  from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-status-updater.vue";
import DetailComponentMultiPerson
  from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-multi-person.vue";
import DetailComponentForm
  from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-form.vue";
import DetailComponentOrganisationAndPersons
  from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-org-and-persons.vue";
import DetailComponentCheckins from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-checkins.vue";

export default class DetailComponentsDependencies {
  public registerDependencies() {
    Vue.component(
      "DetailComponentGeneralInformation",
      DetailComponentGeneralInformation
    );
    Vue.component("DetailComponentText", DetailComponentText);
    Vue.component("DetailComponentSummary", DetailComponentSummary);
    Vue.component(
      "DossierDetailComponentActions",
      DossierDetailComponentActions
    );
    Vue.component("DossierDetailComponentButton", DossierDetailComponentButton);
    Vue.component("DetailComponentTabs", DetailComponentTabs);
    Vue.component("DetailComponentExchanges", DetailComponentExchanges);
    Vue.component("DossierDetailComponentLotTab", DossierDetailComponentLotTab);
    Vue.component(
      "DossierDetailComponentGeneralTab",
      DossierDetailComponentGeneralTab
    );
    Vue.component(
      "DossierDetailComponentTabsWithGeneralAndLots",
      DossierDetailComponentTabsWithGeneralAndLots
    );
    Vue.component("DetailComponentOrganisation", DetailComponentOrganisation);
    Vue.component("DetailComponentPerson", DetailComponentPerson);
    Vue.component("DetailComponentList", DetailComponentList);
    Vue.component(
      "DetailComponentDocumentChecklist",
      DetailComponentDocumentChecklist
    );
    Vue.component("DetailComponentLinearSection", DetailComponentLinearSection);
    Vue.component(
      "DetailComponentDossierLotSummary",
      DetailComponentDossierLotSummary
    );
    Vue.component("DetailComponentProducts", DetailComponentProducts);
    Vue.component(
      "DetailComponentLinearSectionMultiplicity",
      DetailComponentLinearSectionMultiplicity
    );
    Vue.component("DetailComponentExchangeSteps", DetailComponentExchangeSteps);
    Vue.component("DetailComponentHeader", DetailComponentHeader);
    Vue.component("ModifyLotsDialog", ModifyEntitiesDialog);
    Vue.component("DetailComponentAddress", DetailComponentAddress);
    Vue.component("DetailComponentContact", DetailComponentContact);
    Vue.component("DetailComponentDate", DetailComponentDate);
    Vue.component("DetailComponentState", DetailComponentState);
    Vue.component("DetailComponentPlanning", DetailComponentPlanning);
    Vue.component("WidgetComponentLink", WidgetComponentLink);
    Vue.component("WidgetComponentText", WidgetComponentText);
    Vue.component("WidgetComponentVertical", WidgetComponentVertical);
    Vue.component("WidgetComponentDossierAtgs", WidgetComponentDossierAtgs);
    Vue.component("DetailComponentStatusUpdater", DetailComponentStatusUpdater);
    Vue.component("DetailComponentMultiPerson", DetailComponentMultiPerson);
    Vue.component("DetailComponentForm", DetailComponentForm);
    Vue.component("DetailComponentOrganisationAndPersons", DetailComponentOrganisationAndPersons);
    Vue.component("DetailComponentCheckins", DetailComponentCheckins);

  }
}

export const dossierDetailComponentsDependencies =
  new DetailComponentsDependencies();
