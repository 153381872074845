


















































import { Component, Vue } from "vue-property-decorator";
import ActivityDependendViewBase from "@/app/views/activity-dependend-view.base";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import Loader from "@/components/common/Loader.vue";
import ActionTile from "@/components/common/ActionTile.vue";
import MyDocumentsView from "@/views/documents/MyDocumentsView.vue";
import {
  FormDialogControl,
  FormDialogModel,
} from "@/app/components/dialog/form-dialog.model";
import { RenderType } from "@/app/Types";
import { ExternalContext } from "@/app/contexts/externalContext";
import FormDialogComponent from "@/app/components/dialog/form-dialog-component.vue";
import ActivityDefinitionModel from "@/app/models/activity/activity-definition.model";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import { OverviewControl, OverviewEvent } from "@/app/dynamic-components/overviews/overiew.model";
import { checkinService } from "@/app/services/checkin.service";
import { DynamicForm } from "@/app/dynamic-components/forms/dynamic-form.model";

@Component({
  components: {
    FormDialogComponent,
    MyDocumentsView,
    ActionTile,
    Loader,
    AuthenticationInterceptor,
    AcceptPoliciesInterceptor,
    PageHeader,
    OverviewComponent,
  },
})
export default class CheckinsOverviewView extends ActivityDependendViewBase {
  additionalFilters: { [key: string]: string } = {};
  additionalMetadata: { [key: string]: any } = {};
  externalContext: ExternalContext = new ExternalContext();

  async onActivityUpdated(): Promise<any> {
    const activityDefinition = this.$store.state.activity.currentActivityDefinition as ActivityDefinitionModel;
    const detailDefinitionId = activityDefinition.dossiers.entries[0].dossierDetailDefinitionId;
    await dossierServiceV2.getDossierDetailDefinition(detailDefinitionId)
      .then((def) => {
        if (def) {
          this.externalContext.setData("activityDefinition", activityDefinition);
          this.externalContext.setData("detailDefinitionId", detailDefinitionId);
          this.externalContext.setData("dossierdefinition", def);
        }
      });
    return;
  }

  showCreateDialog() {
    this.createNewFormDialogControl.show();
  }

  private createNewFormDialogControl!: FormDialogControl;
  recieveCreateNewFormDialogControl(control: FormDialogControl) {
    this.createNewFormDialogControl = control;
    this.createNewFormDialogControl.submitChanges = this.createNewPlanning;
  }

  createNewPlanning(formData: any): Promise<boolean> {
    console.warn("create new planning", formData);
    return Promise.resolve(true);
  }

  private checkoutItem: any = null;
  public editPressed(overviewEvent: OverviewEvent) {
    console.log(overviewEvent);
    this.checkoutItem = overviewEvent.item
    this.checkinCompleteControl?.show(overviewEvent.item.id, overviewEvent.item);
    setTimeout(()=> {
      this.overviewControl?.sendEvent({
        type: "CLEAR_ACTION",
        item: null,
        metadata: {},
      });
    },2000);

  }

  private overviewControl: OverviewControl | null = null;
  public recieveOverviewControl(overviewControl: OverviewControl) {
    this.overviewControl = overviewControl;
  }

  protected checkinCompleteModel: FormDialogModel | null = null;
  protected checkinCompleteControl: FormDialogControl | null = null;

  protected recieveCheckinCompleteControl(control: FormDialogControl) {
    this.checkinCompleteControl = control;
    control.submitChanges = this.completeCheckin;
    control.afterSubmitChanges = this.setExternalEntiity;
  }

  public async setExternalEntiity(
    form: DynamicForm,
    formData: any,
    referenceId?: string
  ): Promise<any> {
    return true;
  }

  public completeCheckin(
    form: DynamicForm,
    formData: any,
    referenceId?: string
  ): Promise<boolean> {
    form.patchForm({
      actualEndDate: new Date(),
    });
    return checkinService
      .completeCheckin(form, this.checkoutItem.id, form.formData)
      .then((value) => {
        this.checkoutItem = null;
        this.overviewControl?.refresh();
        return true;
      });
  }
}
