import DialogComponent from "@/app/components/dialog/form-dialog-component.vue";
import { RenderItem, RenderType } from "@/app/Types";
import { ExternalContext } from "@/app/contexts/externalContext";
import { DynamicForm } from "@/app/dynamic-components/forms/dynamic-form.model";

export interface FormDialogModel {
  title: string;
  form: RenderItem;
  externalContext: ExternalContext;

  referenceId: string;
  referenceType: string;

  destroyLabel: string | null;
  submitLabel: string | null;
}

export class FormDialogControl {
  private view: DialogComponent;

  submitChanges: (form: DynamicForm, formData: any, referenceId?: string) => Promise<boolean> = (form, formData, referenceId) => {
    return Promise.resolve(true);
  };
  afterSubmitChanges: (form: DynamicForm, formData: any, referenceId?: string) => Promise<any> = (form, formData, referenceId) => {
    return Promise.resolve(true);
  };

  destroyItem: (form: DynamicForm, formData: any, referenceId: string) => Promise<boolean> = (f, fd, iteù) => {
    return Promise.resolve(true);
  };
  afterDestroyItem: (form: DynamicForm, formData: any, referenceId: string) => Promise<any> = (f, fd, iteù) => {
    return Promise.resolve(true);
  };
  constructor(view: DialogComponent) {
    this.view = view;
  }

  show(referenceId?: string, initFormData?: any) {
    if (this.view) this.view.showDialog(referenceId, initFormData);
  }

  setReferenceId(referenceId: string){
    if (this.view?.formDialogModel) this.view.formDialogModel.referenceId = referenceId;
  }

  getReferenceId(): string | undefined{
    return this.view?.formDialogModel?.referenceId
  }
}
