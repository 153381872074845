
















import { Component, Vue } from "vue-property-decorator";
import ActivityDependendViewBase from "@/app/views/activity-dependend-view.base";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import Loader from "@/components/common/Loader.vue";
import ActionTile from "@/components/common/ActionTile.vue";
import MyDocumentsView from "@/views/documents/MyDocumentsView.vue";
import MyDossiersView from "@/views/dossiers/MyDossiersView.vue";
import DashboardView from "@/views/dashboard/DashboardView.vue";
import DetailComponentDashboard from "@/app/dynamic-components/details/detail-component-dashboard.vue";

@Component({
  components: {
    DetailComponentDashboard,
    DashboardView,
    MyDossiersView,
    MyDocumentsView,
    ActionTile,
    Loader,
    AuthenticationInterceptor,
    AcceptPoliciesInterceptor,
    PageHeader,
    OverviewComponent,
  },
})
export default class DashboardV2View extends ActivityDependendViewBase {
  additionalFilters: { [key: string]: string } = {};
  additionalMetadata: { [key: string]: any } = {};

  dashboardDefinitionId: string | null = null;

  async onActivityUpdated(): Promise<any> {
    this.dashboardDefinitionId = this.activityDefinition?.dashboard?.definitionId;
  }

}
