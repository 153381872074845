
















































































































































































































































































































































import Component from "vue-class-component";
import Vue from "vue";
import { Subject, Subscription } from "rxjs";
import SortConfig from "@/contracts/generic/SortConfig";
import { TableColumns } from "@/constants/TableColumns";
import PageHeader from "@/components/layout/PageHeader.vue";
import MyDossiersFilters from "@/components/dossiers/my-dossiers/MyDossiersFilters.vue";
import MyDossiersTable from "@/components/dossiers/my-dossiers/MyDossiersTable.vue";
import CreateDossierTile from "@/components/dossiers/create/CreateDossierTile.vue";
import Loader from "@/components/common/Loader.vue";
import { Prop, Watch } from "vue-property-decorator";
import { overviewService } from "@/app/services/overview.service";
import {
  Overview,
  OverviewDefinition,
  OverviewHeader,
  OverviewPage,
  OverviewRequest,
  searchableItem,
} from "@/app/models/overview/overview.model";
import { DataTableHeader } from "vuetify";
import { overviewCellLookup } from "@/app/dynamic-components/overviews/cells/overview-cell.lookup";
import ActionTile from "@/components/common/ActionTile.vue";
import router, { Routes } from "@/router";
import Router from "@/router";
import { debounceTime, map } from "rxjs/operators";
import TextField from "@/components/common/TextField.vue";
import AeAutocomplete from "@/components/common/Autocomplete.vue";
import {
  OverviewControl,
  OverviewEvent,
} from "@/app/dynamic-components/overviews/overiew.model";
import DossierDetailDocumentExchanges from "@/app/components/dossier-detail-document-exchanges.vue";
import { overviewExpanderLookup } from "@/app/dynamic-components/overviews/expanders/overview-expander.lookup";
import { getObjectContentsFromPath } from "@/app/helpers/stringpath.helper";
import { ruleEngine } from "@/app/services/rule.engine";
import { OverviewContext } from "@/app/contexts/overview.context";
import { ContextAware } from "@/app/contexts/detail.context";
import store from "@/store";
import analyticsService from "@/services/analytics.service";

@Component({
  components: {
    DossierDetailDocumentExchanges,
    AeAutocomplete,
    TextField,
    ActionTile,
    Loader,
    CreateDossierTile,
    MyDossiersTable,
    MyDossiersFilters,
    PageHeader,
  },
})
export default class OverviewComponent extends Vue {
  get attrs() {
    return {
      class: "mb-6",
      boilerplate: false,
      elevation: 2,
    };
  }

  overviewContext: OverviewContext = new OverviewContext();

  @Prop({
    default: () => {
      return {
        title: "Create new",
        subtitle: "Create new",
        icon: "mdi-file-upload",
        largeImage: "no-dossiers.svg",
      };
    },
  })
  protected createNew!: {
    title: string;
    subtitle: string;
    icon: string;
    largeImage: string;
    addNewInImage: boolean
  };

  @Prop({ default: false })
  selectable!: boolean;

  @Prop({ default: "id" })
  selectableProperty!: string;

  @Prop({ default: null })
  protected overviewDefinitionId!: string;
  protected overviewDefinition: OverviewDefinition = {
    columns: [],
    canExpand: false,
    expandType: "",
    element: "",
    defaultSize: 10,
    canCreateNew: false,
    wildcardSearch: false,
    searchable: [],
    defaultSort: TableColumns.START_DATE,
  };

  @Prop({
    default: () => {
      return undefined;
    },
  })
  protected modifyFetchedData!: (overview: Overview) => Overview;

  @Prop({
    default: () => {
      return true;
    },
  })
  protected clickable!: boolean;

  @Prop({ default: true })
  protected canCreateNew!: boolean;

  @Prop({ default: null })
  protected path!: string;

  @Prop({ default: "Generic" }) 
  protected entityType!: string;

  @Prop({ default: "overview" })
  protected tableStyle!: "overview" | "accordion";

  @Prop({ default: () => { return null;} })
  protected embeddedData!: undefined | any[];

  @Prop({ default: () => { return null;} })
  protected additionalMetadata!: { [key: string]: any } | null;

  @Prop({ default: () => { return null;} })
  protected additionalContext!: ContextAware;

  private formStartTime = 0;

  @Watch("additionalContext", { immediate: true, deep: false })
  public onAdditionalContext(
    value: ContextAware | null,
    oldValue: ContextAware | null
  ) {
    if(value){
      this.overviewContext.inherit(value);
    }
  }

  @Prop({
    default: () => {
      return {};
    },
  })
  public additionalFilters!: { [key: string]: string };

  @Watch("additionalMetadata", { immediate: true, deep: true })
  public onAdditionalMetadata(
    value: undefined | any[],
    oldValue: undefined | any[]
  ) {
    this.$forceUpdate();
  }

  selectedItems: any[] = [];
  selectedItem(state: any, item) {
    if (state) {
      this.selectedItems.push(item[this.selectableProperty]);
    } else {
      const i = this.selectedItems.indexOf(item[this.selectableProperty]);
      if (i >= 0) {
        this.selectedItems.splice(i, 1);
      }
    }
    this.$emit("selectedItems", this.selectedItems);
  }

  clearSelectedItems() {
    this.selectedItems = [];
    this.$emit("selectedItems", this.selectedItems);
  }

  hasSelectedItem(item) {
    return this.selectedItems.includes(item[this.selectableProperty]);
  }

  canClickStyle(){
    return this.clickable;
  }

  canClickStyleCss(){
    return this.clickable ? 'pointer !important' : 'default !important';
  }

  public onQuickSearch(newValue: string) {
    this.quickSearch = newValue;
    this.fetchData();
  }

  public onRowClick(item: any, expand: any, isExpanded: boolean, index: any) {
    console.log(`Tracking event: View ${this.entityType} Clicked`, { itemId: item.id });
    analyticsService.sendEvent(
        `${this.entityType} Management`,
        `view_${this.entityType.toLowerCase()}`,
        `View ${this.entityType} Clicked`,
        1,
        { itemId: item.id }
    );
    if (this.tableStyle === "accordion") {
      expand(!isExpanded);
    } else {
      this.rowClicked(item, index);
    }
  }

  @Watch("embeddedData", { immediate: true, deep: true })
  public onEmbeddedDataChange(
    value: undefined | any[],
    oldValue: undefined | any[]
  ) {
    if (value != undefined) {
      this.data = value || [];
      this.page.totalElements = value?.length || 0;
    }
  }

  public page: OverviewPage = {
    number: 0,
    size: 10,
    firstPage: true,
    lastPage: false,
    totalPages: 1,
    totalElements: 1,
  };
  public sort: SortConfig = {
    sortBy: "",
    sortDesc: true,
  };
  public quickSearch = "";
  public filters: { [key: string]: string } = {};

  public _headers: OverviewHeader[] = [];

  filteredHeaders(){
    return this._headers.filter(value => {
      return value.showRule;
    });
  }
  headers(): OverviewHeader[] {
    const headers = JSON.parse(JSON.stringify(this._headers.filter(value => {
      return value.showRule;
    }))).map((value) => {
      value.text = this.$t(value.text) as string;
      return value;
    });
    if (this.selectable) {
      const o: OverviewHeader = {
        text: "",
        sortable: false,
        width: 34,
        type: "Text",
        metadata: {},
        dataSelector: "",
        value: "",
        showRule: true,
        itemRules: {}
      };
      headers.unshift(o);
    }

    return headers;
  }

  public data: any[] = [];

  @Watch("overviewDefinitionId", { immediate: true })
  onOverviewDefinitionIdChanged(val: string, oldVal: string) {
    if (val != oldVal && val) {
      this.initialLoading = true;
      overviewService
        .getOverviewDefinition(val)
        .then((value) => {
          this.setDefinition(value);
          this.initialLoading = false;
        })
        .catch((reason) => {
          this.initialLoading = false;
        });
    }
  }

  filtersUpdated(
    filterId: string,
    value: string,
    searchableItem: searchableItem
  ) {
    const vals = this.searchableItemValues(searchableItem);
    const filterObject = vals.find(
      (val) => val[searchableItem.metadata.itemValue || "value"] === value
    );
    console.warn("search", vals, value, filterId, searchableItem);
    if (filterObject) {
      this.filters = {
        [filterId]: filterObject[searchableItem.metadata.itemValue || "value"],
      };
    } else {
      this.filters = {};
    }
    this.fetchData();
    console.log(`Tracking event: ${this.entityType} Quick filter applied`, { filterId, value });
    analyticsService.sendEvent('Navigation', `quick_filter_${this.entityType.toLowerCase()}`, `${this.entityType} Quick filter applied: ${filterId}`, 1, { filterValue: value });
  }

  initialLoading = false;
  loading = false;

  private overviewControl: OverviewControl = new OverviewControl();

  public rowClicked(item: any, index: number) {
    this.$emit("rowClicked", { item: item, index: index });
  }

  public createNewClicked() {
    this.$emit("createNewClicked");
    this.formStartTime = Date.now();
    console.log(`Create form for ${this.entityType} opened`);
    analyticsService.sendEvent(
        `${this.entityType} Management`,
        `create_${this.entityType.toLowerCase()}_click`,
        `Create New ${this.entityType} Clicked`,
        1
    );
  }

  public fetchDataDo() {
    this.data = [];
    overviewService
      .getOverviewData(this.overviewDefinition, this.overviewDefinitionId, {
        path: this.path,
        page: this.page,
        sort: this.sort,
        q: this.quickSearch,
        filters: { ...this.additionalFilters, ...this.filters },
      })
      .then((value) => {
        if (
          value &&
          value._embedded &&
          value._embedded["results"] &&
          Array.isArray(value._embedded["results"])
        ) {
          if (this.modifyFetchedData) {
            this.data = this.modifyFetchedData.apply(this, [value])._embedded[
              "results"
            ];
          } else {
            this.data = value._embedded["results"];
          }

          this.page = value._page;
        } else {
          this.data = [];
          this.page = {
            number: 0,
            size: 10,
            firstPage: true,
            lastPage: false,
            totalPages: 1,
            totalElements: 1,
          };
        }
        this.loading = false;
        this.eventbus.next({ type: "FETCHED_DATA", metadata: {}, item: {} });
      })
      .catch((reason) => {
        console.error(
          "failed to fetch overview data",
          reason,
          this.overviewDefinition
        );
        this.loading = false;
      });
  }
  public fetchData() {
    if (!this.overviewDefinition && this.overviewDefinitionId) return;
    if (this.embeddedData != undefined) return;
    this.loading = true;
    this.fetchDataSubject.next();
  }

  eventbus: Subject<OverviewEvent> = new Subject<OverviewEvent>();
  eventbusSubscription: Subscription | undefined = undefined;

  fetchDataSubject: Subject<any> = new Subject<any>();
  fetchDataSubscription: Subscription | undefined = undefined;

  mounted(): void {
    this.overviewControl.setView(this);
    this.$emit("overviewControl", this.overviewControl);
    this.fetchDataSubscription = this.fetchDataSubject
      .pipe(debounceTime(200))
      .subscribe(() => {
        this.fetchDataDo();
      });
    this.eventbusSubscription = this.eventbus.subscribe((event) => {
      switch (event.type) {
        case "ACTION":
          this.$emit("actionButtonPressed", event);
          break;
        default:
          console.warn("overview event not handled", event);
      }
    });
    console.log(this.overviewDefinition.searchable);
  }

  beforeDestroy(): void {
    if (this.eventbusSubscription) {
      this.eventbusSubscription.unsubscribe();
    }
    if (this.fetchDataSubscription) {
      this.fetchDataSubscription.unsubscribe();
    }
  }

  private setDefinition(value: OverviewDefinition) {
    this.overviewDefinition = value;
    if (
      this.overviewDefinition.defaultSize &&
      this.overviewDefinition.defaultSize !== 0
    ) {
      this.page.size = this.overviewDefinition.defaultSize;
    }

    this.overviewContext.setData("activity", this.$store.state.activity.currentActivityDefinition);
    const user = this.$store.state.user;
    this.overviewContext.setData("user", {
      contactId: user.contactId,
      organizationId: user.organizationId,
      language: user.language,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      roles: user.roles,
      roleIds: user.roleIds
    });
    this.overviewContext.setData("definition", value);
    this.overviewContext.setData("bccaId", store.state.appConfig.bccaId);

    this._headers = this.overviewDefinition.columns.map((c) => {
      return {
        text: c.name,
        value: c.id,
        type: c.type,
        metadata: c.metadata,
        dataSelector: c.dataSelector,
        sortable: c.sortable,
        showRule: !c.showRule || c.showRule === '' ? true : ruleEngine.resolveRule(this.overviewContext, this.overviewContext.resolvePlaceholders(c.showRule)),
        itemRules: c.itemRules || {}
      };
    });
    //set filters somehow
    if(!this.overviewDefinition?.defaultSort || this.overviewDefinition.defaultSort === ""){
      this.sort.sortBy = TableColumns.START_DATE;
    }else{
      if(this.overviewDefinition.defaultSort.endsWith(',desc')){
        this.sort.sortDesc = true;
        this.sort.sortBy = this.overviewDefinition.defaultSort.split(',')[0];
      }else{
        this.sort.sortDesc = false;
        this.sort.sortBy = this.overviewDefinition.defaultSort.split(',')[0];
      }
    }

    this.fetchData();

    this.$forceUpdate();
  }

  pageUpdated(page: number | undefined): void {
    if (page) {
      this.page.number = page - 1;
      this.fetchData();
    }
  }

  sortByUpdated(sortBy: string | undefined): void {
    if (sortBy) {
      this.sort.sortBy = sortBy;
      this.fetchData();
      console.log(`Tracking event: ${this.entityType} Sorted by`, sortBy);
      analyticsService.sendEvent('Navigation', `sort_${this.entityType.toLowerCase()}`, `${this.entityType} Sorted by: ${sortBy}`, 1);
    }
  }

  sortDescUpdated(sortDescending: boolean | undefined): void {
    if (sortDescending != undefined) {
      this.sort.sortDesc = sortDescending;
      this.fetchData();
      console.log(`Tracking event: ${this.entityType} Sort Descending`, sortDescending);
      analyticsService.sendEvent('Navigation', `sort_${this.entityType.toLowerCase()}`, `${this.entityType} Sort Descending: ${sortDescending}`, 1);
    }
  }

  itemsPerPageUpdated(pageSize: number | undefined): void {
    if (pageSize) {
      this.page.size = pageSize;
      this.fetchData();
    }
  }

  getOverviewCellComponent(type: string) {
    return overviewCellLookup.getComponent(type);
  }

  getOverviewExpanderComponent(type: string) {
    return overviewExpanderLookup.getComponent(type);
  }

  searchableItemValues(searchableItem: searchableItem): any[] {
    if (typeof searchableItem.metadata.enums === "string") {
      if (!searchableItem.metadata.enums.startsWith("data.external."))
        return [];
      const result = getObjectContentsFromPath(
        searchableItem.metadata.enums.replace("data.external.", ""),
        this.additionalMetadata
      );
      //console.log("resolveValue", this.additionalMetadata, result);
      if (Array.isArray(result)) {
        return result;
      } else {
        return Object.values(result);
      }
    } else if (Array.isArray(searchableItem.metadata.enums)) {
      return searchableItem.metadata.enums;
    } else {
      return Object.entries(searchableItem.metadata.enums).map((value) => {
        return {
          label: value[1],
          value: value[0],
        };
      });
    }
  }
}
