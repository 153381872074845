import DetailComponentSummary from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-summary.vue";
import DetailComponentTabs from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-tabs.vue";
import DetailComponentText from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-text.vue";
import DetailComponentExchanges from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-exchanges.vue";
import Vue from "vue";
import DetailComponentOrganisation from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-organisation.vue";
import DetailComponentPerson from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-person.vue";
import DetailComponentExchangeSteps from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-exchange-steps.vue";
import DetailComponentHeader from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-header.vue";
import ModifyEntitiesDialog from "@/app/dynamic-components/details/details-components/dialogs/ModifyLotsDialog.vue";
import WidgetComponentText from "@/app/dynamic-components/details/details-components/widgets/widget-component-text.vue";
import WidgetComponentDossierAtgs from "@/app/dynamic-components/details/details-components/widgets/widget-component-dossier-atgs.vue";
import DetailComponentForm
  from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-form.vue";
import DetailComponentOrganisationAndPersons
  from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-org-and-persons.vue";
import DetailComponentCheckins from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-checkins.vue";

interface DetailComponentDictionaryInterface {
  [key: string]: string;
}

export default class DetailComponentLookup {
  private detailComponentTypeDictionary: DetailComponentDictionaryInterface = {
    General: "DetailComponentGeneralInformation",
    DetailText: "DetailComponentText",
    Summary: "DetailComponentSummary",
    Actions: "DossierDetailComponentActions",
    DetailButton: "DossierDetailComponentButton",
    Tabs: "DetailComponentTabs",
    Exchanges: "DetailComponentExchanges",
    DossierDetailComponentLotTab: "DossierDetailComponentLotTab",
    DossierDetailComponentGeneralTab: "DossierDetailComponentGeneralTab",
    TabsWithGeneralAndLots: "DossierDetailComponentTabsWithGeneralAndLots",
    Organisation: "DetailComponentOrganisation",
    Person: "DetailComponentPerson",
    OrgAndPersons: "DetailComponentOrganisationAndPersons",
    List: "DetailComponentList",
    DocumentsChecklist: "DetailComponentDocumentChecklist",
    LinearSection: "DetailComponentLinearSection",
    DossierLotSummary: "DetailComponentDossierLotSummary",
    ProductsOverview: "DetailComponentProducts",
    LinearSectionMultiplicity: "DetailComponentLinearSectionMultiplicity",
    DetailedExchange: "DetailComponentExchangeSteps",
    DetailHeader: "DetailComponentHeader",
    modifyLots: "ModifyLotsDialog",
    Address: "DetailComponentAddress",
    Contact: "DetailComponentContact",
    Date: "DetailComponentDate",
    State: "DetailComponentState",
    Planning: "DetailComponentPlanning",
    StatusUpdater: "DetailComponentStatusUpdater",
    MultiPerson: "DetailComponentMultiPerson",
    DetailForm: "DetailComponentForm",
    Checkins: "DetailComponentCheckins",

    //widgets
    WidgetLink: 'WidgetComponentLink',
    WidgetText: 'WidgetComponentText',
    WidgetLayoutVertical: 'WidgetComponentVertical',
    WidgetComponentDossierAtgs: 'WidgetComponentDossierAtgs'
  };

  getComponentFromDictionary = (componentString: string): string => {
    const result: string = this.detailComponentTypeDictionary[componentString];

    if (!result) {
      console.error(`Component ${componentString} not found in dictionary`);
      return "";
    }

    return result;
  };
}

export const detailComponentLookup = new DetailComponentLookup();
