











import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { DynamicDetailEntry } from "@/app/dynamic-components/details/dynamic-detail.model";
import { DetailContext } from "@/app/contexts/detail.context";
import { Component } from "vue-property-decorator";

export type ListMetaData = {
  selector: string;
  property: string;
  translated: string;
  fromIndexedList: string //point to a list in the definitions, the value will be used to resolve the object of that list (index based)
};

@Component({})
export default class DetailComponentList extends DetailComponentBase<ListMetaData> {
  value: any = null;

  postEntityChanged(newValue: DynamicDetailEntry) {
    if (this.detailContext && this.entry) {
      this.value = this.detailContext.resolveDataPath(this.entry.metadata.selector);
      if(this.entry.metadata.fromIndexedList && this.value && Array.isArray(this.value)) {
        const indexList = this.detailContext.resolveDataPath(this.entry.metadata.fromIndexedList);
        this.value = this.value.map(value1 => indexList[value1] || value1);
      }
    }
  }

  protected postDetailContextChanged(newValue: DetailContext) {
    if (this.entry && this.detailContext) {
      this.value = this.detailContext.resolveDataPath(this.entry.metadata.selector);
      if(this.entry.metadata.fromIndexedList && this.value && Array.isArray(this.value)) {
        const indexList = this.detailContext.resolveDataPath(this.entry.metadata.fromIndexedList);
        this.value = this.value.map(value1 => indexList[value1] || value1);
      }
    }
  }

  get displayvalue(): string {
    if (!this.value || !this.entry || !this.metadata) return "-";
    let val: string[];
    if (!Array.isArray(this.value)) {
      val = [this.value];
    } else {
      val = this.value;
    }
    if (this.metadata.property) {
      val = val.map((value1) => value1[this.metadata.property]);
    }
    if (this.metadata.translated) {
      val = val.map((v) => this.$tc(v));
    }
    return val.join(", ");
  }
}
