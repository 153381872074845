


































































































































































































































































import Vue from "vue";
import FormWrapper from "@/app/dynamic-components/forms/FormWrapper.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import {
  ExchangeDefinition,
  ExchangeDefinitionStep,
} from "@/app/dynamic-components/details/dynamic-detail.model";
import { RenderType } from "@/app/Types";
import {
  Exchange,
  ExchangeControl,
  ExchangeEvent,
  ExchangeStep,
} from "@/app/components/exchange/exchange.model";
import { BehaviorSubject, Subject, Subscription, combineLatest } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { DynamicForm } from "@/app/dynamic-components/forms/dynamic-form.model";
import { exchangeMock } from "@/app/mock/exchange.mock";
import { ExternalContext } from "@/app/contexts/externalContext";
import { userServiceV2 } from "@/app/services/user.service";
import { organisationService } from "@/app/services/organisation.service";
import UserInfo from "@/contracts/generic/UserInfo";
import OrganizationDto from "@/contracts/generic/OrganizationDto";
import Loader from "@/components/common/Loader.vue";
import { dateFormat } from "@vuejs-community/vue-filter-date-format";
import { exchangeService } from "@/app/services/exchange.service";
import { ruleEngine } from "@/app/services/rule.engine";

@Component({
  methods: { dateFormat },
  computed: {
    RenderType() {
      return RenderType;
    },
  },
  components: { Loader, FormWrapper },
})
export default class ExchangeComponent extends Vue {
  get attrs() {
    return {
      class: "mb-6",
      boilerplate: false,
      elevation: 2,
    };
  }

  protected loaded = false;

  protected externalFormContext: { [key: string]: ExternalContext } = {};
  protected stepPosition = 1;
  protected inProgressPosition = 1;
  protected isBlockingStepPosition = 1;
  protected isSaving = false;

  protected exchangeControl: ExchangeControl | undefined;

  protected eventbus = new Subject<ExchangeEvent>();
  private eventbusSubscription: Subscription | null = null;

  protected isAdmin = false;
  protected adminModeExternalFormContextCanEditBackup: { [key: string]: string } = {};
  protected adminMode = false;

  private definitionSetSubject = new BehaviorSubject<
    ExchangeDefinition | undefined
  >(undefined);
  private exchangeSetSubject = new BehaviorSubject<Exchange | null | undefined>(
    undefined
  );
  private involvedPersonsSetSubject = new BehaviorSubject<
    { [key: string]: string } | undefined
  >(undefined);
  private combinedPropsSetSubscription: Subscription | null = null;

  @Prop({ default: null })
  referenceId!: string | null;

  @Prop({ default: false })
  readonly!: boolean;

  @Prop({ default: null })
  referenceType!: string | null;

  @Prop({ default: () => new ExternalContext() })
  externalContext!: ExternalContext;

  protected exchangeDefinition: ExchangeDefinition | null = null;
  @Prop({ default: null })
  exchangeDefinitionId!: string | null;
  @Watch("exchangeDefinitionId", { immediate: true, deep: true })
  setDefinition(
    newExchangeDefinitionId: string | null,
    oldExchangeDefinitionId: string | null
  ) {
    if (newExchangeDefinitionId === oldExchangeDefinitionId) return;
    if (!newExchangeDefinitionId) return;

    exchangeService
      .getExchangeDefinition(newExchangeDefinitionId)
      .then((value) => {
        this.definitionSetSubject.next(value);
      })
      .catch((reason) => {
        console.warn("Failed to fetch exchange definition", reason);
      });
  }

  // when an existing exchange is loaded into the stepper. This can be by completing the first step. Or by recieving an existing from external
  protected exchange: Exchange | null = null;

  @Prop({ default: undefined })
  exchangeId!: string | null;
  @Watch("exchangeId", { immediate: true, deep: true })
  setExchange(newExchangeId: string | null, oldExchangeId: string | null) {
    if (newExchangeId === oldExchangeId) return;

    if (newExchangeId === null) {
      //exchange doesn't exist yet
      this.exchangeSetSubject.next(null);
    } else {
      //otherwise fetch exchange
      exchangeService
        .getExchange(newExchangeId)
        .then((value) => {
          this.exchangeSetSubject.next(value);
        })
        .catch((reason) => {
          console.warn("Failed to fetch exchange", reason);
        });
    }
  }

  @Prop({ default: null })
  involvedParties!: { [key: string]: string };
  @Watch("involvedParties", { immediate: true, deep: true })
  setInvolvedParties(
    newInvolvedParties: { [key: string]: string } | null,
    oldInvolvedParties: { [key: string]: string } | null
  ) {
    this.involvedPersonsSetSubject.next(this.involvedParties);
  }
  involvedPartyNames: { [key: string]: string } = {};

  @Watch("stepPosition", { immediate: true, deep: true })
  onStepPositionChanged(newStepPosition: number, oldStepPosition: number) {
    if (newStepPosition === oldStepPosition) return;
    this.setStepPosition(newStepPosition);
  }
  mounted() {
    this.exchangeControl = new ExchangeControl(this);
    this.$emit("exchangeControl", this.exchangeControl);
    this.eventbusSubscription = this.eventbus.subscribe((event) => {
      switch (event.action) {
        case "SUBMIT":
          this.completeStep();
          break;
        case "VIEWNEXT":
          this.viewNextStep();
          break;
        default:
          console.warn("triggered event", event);
      }
    });
    this.$emit("controlbus", this.eventbus);

    this.combinedPropsSetSubscription = combineLatest(
      this.definitionSetSubject,
      this.exchangeSetSubject,
      this.involvedPersonsSetSubject
    )
      .pipe(debounceTime(500))
      .subscribe(async (merge) => {
        const definition = merge[0];
        const exchange = merge[1];
        //const involvedParties = merge[2];

        this.exchangeDefinition = definition || null;

        if (this.exchangeDefinition) {
          if (exchange != null) {
            this.exchange = exchange;
            this.patchExchangeDataToForms();
          } else {
            this.exchange = ExchangeComponent.CONSTRUCT_EXCHANGE(
              this.exchangeDefinition,
              this.involvedParties
            );
          }

          this.resolveEditableStepState();
          await this.resolveInvolvedPartyNames();

          this.setState();

          this.isAdmin = ruleEngine.resolveRule(this.externalContext, this.exchangeDefinition.adminRule || '');
          this.loaded = true;
        }
      });
  }

  beforeDestroy(): void {
    if (this.eventbusSubscription) {
      this.eventbusSubscription.unsubscribe();
    }
    if (this.combinedPropsSetSubscription) {
      this.combinedPropsSetSubscription.unsubscribe();
    }
  }

  toggleAdminMode(){
    if(this.adminMode){
      this.adminMode = false;
      this.exchangeDefinition?.steps.forEach((step) => {
        this.externalFormContext[step.id].setData("canEdit",   this.adminModeExternalFormContextCanEditBackup[step.id]);
      });
      this.adminModeExternalFormContextCanEditBackup = {};
    }else{
      this.adminMode = true;
      this.adminModeExternalFormContextCanEditBackup = {};
      this.exchangeDefinition?.steps.forEach((step) => {
        this.adminModeExternalFormContextCanEditBackup[step.id] = this.externalFormContext[step.id]?.data?.canEdit;
        this.externalFormContext[step.id].setData("canEdit",   "editable");
      });
    }
    this.$forceUpdate();
  }

  get id(): string {
    return this.$store.state.user.iamUserId;
  }

  isFreeFormStepBlocking(): boolean {
    if (this.exchangeDefinition?.type !== 'FREEFILL') {
      return false;
    }

    const stepsToCheck = this.exchangeDefinition?.steps.slice(0, this.stepPosition);
    for (let index = 0; index < stepsToCheck.length; index++){
      const step = stepsToCheck[index];
      console.warn('indexes', index, this.inProgressPosition, this.stepPosition)
      if (step.freeFormIsBlockingStep && this.exchange?.steps[index].state !== "COMPLETED" && this.inProgressPosition !== this.stepPosition) {
        this.isBlockingStepPosition = index;
        return true;
      }
    }
    return false;
  }

  forms: { [key: string]: DynamicForm } = {};
  recieveForm(form: DynamicForm, step: ExchangeDefinitionStep) {
    this.forms[step.id] = form;
  }

  patchExchangeDataToForms() {
    //forms need to arrive first, sadly no way to check atm
    setTimeout(() => {
      this.exchange?.steps.forEach((existingStep) => {
        if (
          (existingStep.state === "COMPLETED" ||
            existingStep.state === "INPROGRESS") &&
          this.forms[existingStep.id]
        ) {
          this.forms[existingStep.id].patchForm(existingStep.data);
          setTimeout(() => {
            this.forms[existingStep.id].resetValidation();
          }, 100);
        }
      });
    }, 500);
  }

  public formData: { [key: string]: any } = {};
  onFormDataChanged(formData: any, step: ExchangeDefinitionStep) {
    this.formData[step.id] = formData;
  }

  public isCurrentStepParty() {
    if (!this.exchange) return false;
    if (!this.exchangeDefinition) return false;

    const currentStepIndex = this.stepPosition - 1;
    if (currentStepIndex < 0) return false;

    const currentDefinitionStep =
      this.exchangeDefinition.steps[currentStepIndex];

    const foundInvolvedParties = this.exchange.involvedParties.filter(
      (value) => value.id === currentDefinitionStep.involvedParty
    );

    // if party was not found, it is readonly
    if (foundInvolvedParties.length <= 0) {
      return false;
    }

    const foundInvolvedParty = foundInvolvedParties[0];
    if (foundInvolvedParty.contactType === "USER") {
      return foundInvolvedParty.contactId === this.$store.state.user.contactId;
    } else {
      return (
        foundInvolvedParty.contactId === this.$store.state.user.organizationId
      );
    }
  }

  async setStepPosition(stepNumber: number) {
    if (!this.exchangeDefinition || !this.exchange) return;

    console.warn("trying to move to step", stepNumber);
    this.stepPosition = stepNumber;
    if (
      this.stepPosition < this.exchangeDefinition.steps.length &&
      this.stepPosition > 0
    ) {
      const step = this.exchangeDefinition.steps[this.stepPosition - 1];
      if (this.forms[step.id]) {
        this.forms[step.id].patchForm(
          this.exchange.steps[this.stepPosition - 1].data
        );
        setTimeout(() => {
          this.forms[step.id].resetValidation();
        }, 100);
      }
    }
  }

  cancel() {
    this.eventbus.next({
      action: "CLOSE",
      metadata: {},
    });
  }

  viewNextStep() {
    this.setStepPosition(this.stepPosition + 1);
  }

  async rejectPrevStep() {
    if (!this.exchangeDefinition || !this.exchange) return;
    await this.moveExchange(false);
  }

  async patchStep(stepPosition?: number, admin?: boolean) {
    if(typeof stepPosition !== "number" ) stepPosition = undefined;
    if (!this.exchangeDefinition || !this.exchange) return;
    let modifyingStepDefinitionId = stepPosition !== undefined ? this.exchange.steps[stepPosition-1].id : this.exchange.currentStep;

    const errors = await this.forms[modifyingStepDefinitionId].getErrors();
    if (errors.length === 1) {
      this.$toast.error("Validation failed: " + errors[0].description);
      return;
    }
    if (errors.length > 1) {
      this.$toast.error("Multiple validations failed.");
      return;
    }

    const modifyingStepIndex = this.exchange.steps.findIndex(
      (value) => value.id === modifyingStepDefinitionId
    );
    if (modifyingStepIndex < 0) {
      this.$toast.error("Failed to resolve current step");
      return;
    }
    const modifyingStepStepId = this.exchange.steps[modifyingStepIndex].stepId;

    if(admin && this.exchange && Array.isArray(this.exchange.involvedParties)){
      //check if admin is in involved parties, if not add him. Otherwise he won't be able to save
      // parties cannot be dynamiclly added. this is safer anyway, but is here as a reminder.
    }

    this.isSaving = true;
    try {
      const beforeSaveSuccess = await this.forms[modifyingStepDefinitionId].beforeSave();
      if (!beforeSaveSuccess) {
        this.$toast.error("Couldn't save attachments.");

        this.isSaving = false;
        return;
      }
    } catch (ex) {
      this.$toast.error("Couldn't save attachments.");
      this.isSaving = false;
      return;
    }

    if (
      this.stepPosition === 1 &&
      (!this.exchange?.id || this.exchange?.id === "")
    ) {
      await this.createExchange();
      modifyingStepDefinitionId = this.exchange.currentStep;
    }
    await this.patchExchange(modifyingStepDefinitionId);

    try {
      const afterSaveSuccess = await this.forms[modifyingStepDefinitionId].afterSave({
        type: "exchange",
        referenceId: modifyingStepStepId,
      });
      if (!afterSaveSuccess) {
        this.$toast.error("Some post actions failed!");

        this.isSaving = false;
        return;
      }
    } catch (ex) {
      this.$toast.error("Couldn't save attachments.");
      this.isSaving = false;
      return;
    }
  }

  async completeStep() {
    if (!this.exchangeDefinition || !this.exchange) return;

    const errors = await this.forms[this.exchange.currentStep].getErrors();
    console.warn("errors", errors, JSON.stringify(errors));
    if (errors.length === 1) {
      this.$toast.error("Validation failed: " + errors[0].description);
      return;
    }
    if (errors.length > 1) {
      this.$toast.error("Multiple validations failed.");
      return;
    }

    const modifyingStepDefinitionId = this.exchange.currentStep;
    const modifyingStepIndex = this.exchange.steps.findIndex(
      (value) => value.id === modifyingStepDefinitionId
    );
    if (modifyingStepIndex < 0) {
      this.$toast.error("Failed to resolve current step");
      return;
    }
    let modifyingStepStepId = this.exchange.steps[modifyingStepIndex].stepId;

    this.isSaving = true;
    try {
      const beforeSaveSuccess = await this.forms[
        this.exchange.currentStep
      ].beforeSave();
      if (!beforeSaveSuccess) {
        this.$toast.error("Couldn't save attachments.");

        this.isSaving = false;
        return;
      }
    } catch (ex) {
      this.$toast.error("Couldn't save attachments.");
      this.isSaving = false;
      return;
    }

    if (
      this.stepPosition === 1 &&
      (!this.exchange?.id || this.exchange?.id === "")
    ) {
      await this.createExchange();
      modifyingStepStepId = this.exchange.steps[modifyingStepIndex].stepId;
      await this.moveExchange(true);
    } else if (this.stepPosition === this.exchangeDefinition.steps.length) {
      await this.completeExchange();
    } else {
      await this.moveExchange(true);
    }

    try {
      const afterSaveSuccess = await this.forms[
        modifyingStepDefinitionId
      ].afterSave({
        type: "exchange",
        referenceId: modifyingStepStepId,
      });
      if (!afterSaveSuccess) {
        this.$toast.error("Some post actions failed!");

        this.isSaving = false;
        return;
      }
    } catch (ex) {
      this.$toast.error("Couldn't save attachments.");
      this.isSaving = false;
      return;
    }
  }

  private async createExchange(): Promise<any> {
    if (!this.exchangeDefinition || !this.exchange) return;

    this.isSaving = true;
    console.log("sqdsqdqsdqsd", this.referenceId, this.referenceType);
    return exchangeService
      .createExchange(
        this.exchange,
        this.formData[this.exchange.currentStep],
        this.exchangeDefinition,
        this.referenceId,
        this.referenceType
      )
      .then((newExchange) => {
        this.exchange = newExchange;
        console.warn("created an exchange", this.exchange);
        this.isSaving = false;
      })
      .catch((reason) => {
        console.warn("failed to create exchange", reason);
        this.isSaving = false;
      });
  }

  private moveExchange(nextStep: boolean): Promise<any> {
    if (!this.exchangeDefinition || !this.exchange)
      return Promise.reject("No exchange or definition to move");

    this.isSaving = true;
    return exchangeService
      .moveExchange(
        this.exchange,
        this.formData[this.exchange.currentStep],
        nextStep
      )
      .then((value) => {
        this.exchange = value;
        this.setState();
        this.isSaving = false;
        if(this.adminMode) this.adminMode = false;
        this.$forceUpdate();
      })
      .catch((reason) => {
        console.warn("failed to move exchange", reason);
        this.isSaving = false;
      });
  }

  private patchExchange(stepDefinitionId: string): Promise<any> {
    if (!this.exchangeDefinition || !this.exchange)
      return Promise.reject("No exchange or definition to move");

    this.isSaving = true;
    return exchangeService
      .patchExchange(this.exchange, this.formData[stepDefinitionId], stepDefinitionId)
      .then((value) => {
        this.exchange = value;
        this.setState(stepDefinitionId);
        this.isSaving = false;
        if(this.adminMode) this.adminMode = false;
        this.$forceUpdate();
      })
      .catch((reason) => {
        console.warn("failed to patch exchange", reason);
        this.isSaving = false;
      });
  }

  private async completeExchange() {
    if (!this.exchangeDefinition || !this.exchange) return;
    exchangeService
      .completeExchange(this.exchange, this.formData[this.exchange.currentStep])
      .then((value) => {
        this.exchange = value;
        this.setState();
      })
      .catch((reason) => {
        console.warn("failed to move exchange", reason);
      });
  }

  private resolveInProgressStep() {
    if (!this.exchange) {
      this.inProgressPosition = 1;
      return;
    }

    if (this.exchange.state === "COMPLETED") {
      this.inProgressPosition = this.exchange.steps.length + 1;
      return;
    }

    this.inProgressPosition = -1;
    for (let i = 0; i < this.exchange.steps.length; i++) {
      if (this.exchange.steps[i].state == "INPROGRESS") {
        this.inProgressPosition = i + 1;
      }
    }
    if (this.inProgressPosition === -1) {
      this.inProgressPosition = this.exchange.steps.length + 1;
    }
  }

  private resolveEditableStepState() {
    if (!this.exchangeDefinition || !this.exchange) return;

    // set editable
    this.externalFormContext = {};
    this.exchangeDefinition.steps.forEach((step) => {
      if (!this.exchangeDefinition || !this.exchange) return;

      // check if the step is in progress, otherwise always false
      const currentStep = this.exchange.steps.find(
        (value) => value.id === step.id
      );

      this.externalFormContext[step.id] = new ExternalContext()
        .inherit(this.externalContext)
        .setData("exchange", this.exchange)
        .setData("definitionstep", step)
        .setData("step", currentStep)
        .setData("canEdit", "readonly");

      let canEdit = true;

      if (
        this.exchangeDefinition.type === "STEPPER" &&
        (!currentStep || currentStep.state !== "INPROGRESS")
      ) {
        canEdit = false;
      }

      if (
        this.exchangeDefinition.type === "FREEFILL" &&
        (!currentStep || currentStep.state === "COMPLETED")
      ) {
        canEdit = false;
      }

      const foundInvolvedParties = this.exchange.involvedParties.filter(
        (value) => value.id === step.involvedParty
      );
      if (foundInvolvedParties.length <= 0) {
        // if party was not found, it is readonly
        canEdit = false;
      }

      const foundInvolvedParty = foundInvolvedParties[0];
      if (foundInvolvedParty.contactType === "USER") {
        if (foundInvolvedParty.contactId !== this.$store.state.user.contactId) {
          canEdit = false;
        }
      } else {
        if (
          foundInvolvedParty.contactId !== this.$store.state.user.organizationId
        ) {
          canEdit = false;
        }
      }

      this.externalFormContext[step.id].setData(
        "canEdit",
        canEdit ? "editable" : "readonly"
      );
    });
    this.$forceUpdate();
  }

  private async resolveInvolvedPartyNames() {
    if (!this.exchangeDefinition || !this.exchange) return;
    const involedParties = this.exchange?.involvedParties;

    const involvedOrganisationParties = involedParties.filter(
      (value) => value.contactType === "ORGANISATION"
    );
    const involvedUserParties = involedParties.filter(
      (value) => value.contactType === "USER"
    );

    console.warn('involvedUserParties',involvedUserParties,involvedOrganisationParties);

    if (involvedUserParties.length > 0) {
      await userServiceV2
        .getUsersByIds(
          involvedUserParties.map((value) => value.contactId as string)
        )
        .then((users) => {
          const usermap: { [key: string]: UserInfo } = {};
          users.forEach((user) => (usermap[user.id] = user));

          involvedUserParties.forEach((invUserParty) => {
            const user = usermap[invUserParty.contactId];
            if (user) {
              this.involvedPartyNames[invUserParty.id] = user.fullName;
            }
          });
          this.$forceUpdate();
        })
        .catch((reason) => {
          console.error("Failed to fetch users by ids", reason);
        });
    }
    if (involvedOrganisationParties.length > 0) {
      await organisationService
        .getOrganisationsByIds(
          involvedOrganisationParties.map((value) => value.contactId as string)
        )
        .then((orgs) => {
          const orgmap: { [key: string]: OrganizationDto } = {};
          orgs.forEach((user) => (orgmap[user.id] = user));

          involvedOrganisationParties.forEach((invOrgParty) => {
            const org = orgmap[invOrgParty.contactId];
            if (org) {
              this.involvedPartyNames[invOrgParty.id] = org.name;
            }
          });
          this.$forceUpdate();
        })
        .catch((reason) => {
          console.error("Failed to fetch orgs by ids", reason);
        });
    }
  }

  public setState(overrideStepId?: string) {
    if (!this.exchangeDefinition) return;

    //resolve current step or, if overriden the step provided in method
    let definitionIndexOfCurrentStep = this.exchangeDefinition.steps.findIndex(
      (value) =>
        value.id ===
        (overrideStepId ? overrideStepId : this.exchange?.currentStep)
    );

    if (this.exchange?.state === "COMPLETED") {
      definitionIndexOfCurrentStep = this.exchange.steps.length - 1;
    }

    if (definitionIndexOfCurrentStep < 0) {
      //this.eventbus.next({ action: "CLOSE", metadata: {} });
      return;
    }

    // index 1 based
    this.setStepPosition(definitionIndexOfCurrentStep + 1);
    this.resolveInProgressStep();
    this.resolveEditableStepState();
  }

  public static CONSTRUCT_EXCHANGE(
    exchangeDefinition: ExchangeDefinition,
    involvedParties?: { [key: string]: string }
  ): Exchange | null {
    if (!exchangeDefinition) return null;
    return {
      id: "",
      exchangeDefinitionId: exchangeDefinition.id,
      state: "INPROGRESS",
      currentStep: exchangeDefinition.steps[0].id,
      steps: exchangeDefinition.steps.map((value, index) => {
        return {
          state: index === 0 ? "INPROGRESS" : "TODO",
          stepId: "",
          id: value.id,
          data: {},
          lastModifiedOn: "",
        };
      }),
      involvedParties: exchangeDefinition.involvedParties.map(
        (involvedPartyDefinition) => {
          return {
            id: involvedPartyDefinition.id,
            contactId: involvedParties
              ? involvedParties[involvedPartyDefinition.id]
              : "",
            roleId: involvedPartyDefinition.roleId,
            contactType: involvedPartyDefinition.contactType,
          };
        }
      ),
      lastModifiedOn: "",
      lastModifiedBy: "",
      createdOn: "",
      createdBy: "",
    };
  }
}
