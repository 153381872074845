






























import { Component } from "vue-property-decorator";
import Autocomplete from "@/components/common/Autocomplete.vue";
import BaseDynamicComponent from "../../form-component.base";
import { DynamicFormEntry } from "@/app/dynamic-components/forms/dynamic-form.model";
export type SelectMetaData = {
  disabled?: boolean;
  value?: string;
  chips: boolean;
  clearable: boolean;
  dense: boolean;
  multiple: boolean;
  items: unknown[];
  loading: boolean;
  itemText: string;
  itemValue: string;
  deletableChips: boolean;
  translate: boolean;
  autoSetOneItem: boolean;
  sortAlphabetically: boolean;
};
@Component({
  components: {
    Autocomplete,
  },
})
export default class SelectBase extends BaseDynamicComponent<SelectMetaData> {
  public items: string[] = [];

  postEntityChanged(entry: DynamicFormEntry) {
    if (entry && entry.metadata) {
      if (entry.metadata.items && Array.isArray(entry.metadata.items)) {
        //entry in metadata
        this.items = entry.metadata.items;
      } else if (
          entry.metadata.items &&
          typeof entry.metadata.items === "string"
      ) {
        //entry in dataresolver
        const resolvedItems = this.entry.root?.resolveDataPath(entry.metadata.items);
        if (!resolvedItems) {
          this.items = [];
        } else {
          if (entry.metadata.property) {
            this.items = resolvedItems.map((p) => p[entry.metadata.property]);
          } else {
            this.items = resolvedItems;
          }
        }
      }
    }
  }

  onSelectContentChanged(newValue: unknown) {
    this.setValueView(newValue);
  }
  onSelectValidityChanged(isValid: boolean) {
    return;
  }
}
